/* .elearning-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    background-color: transparent;
  }
   */
  .elearning-section__content-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  background-color: #ffffff;
  }
  
  .elearning-section__image-content {
    flex: 1;
    padding-right: 20px ;
    width : 100%
  }
  
  .elearning-section__illustration {
    width: 100%;
    max-width: 500px;
    height: auto;
  }
  
  .elearning-section__text-content {
    flex: 1;
  }

  .elearning-section__text-content .elearning-section__title {
    font-size: var(--subtitle);
    font-style: var(--text-fontstyle);
  }

  .elearning-section__text-content p {
    font-family: var(--text-fontstyle);
    font-size: var(--text-size);
    line-height: 30px;
  }
  .elearning-section__text-content p span {
    color  :var(--secondary-color);
    font-weight: bold ;
  }

  
  .elearning-section__title {
    font-size: var(--subtitle);
    font-weight: bold;
    color: var(--primary-color);
    margin-bottom: 1.5rem;
  }
  .elearning-section__title span {
    color : var(--logo-yellow);
  }
  
  .elearning-section__highlight {
    color: var(--secondary-color);
  }
  
  .elearning-section__feature-list {
    list-style: none;
    padding-left: 0;
  }
  
  .elearning-section__feature-list li {
    margin-bottom: 1rem;
    font-size: 1.1rem;
    line-height: 1.6;
    list-style: disc;
    color: #444;
  }
  
  .elearning-section__cta-button {
   margin-top: 1.2em ;
  display: inline-block;
  font-size: var(--text-size);
  font-family: var(--text-fontstyle);
  padding: 10px 20px;
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  border : none ;
  font-weight: bold;
  border-radius: 5px;
  background-color: var(--logo-yellow);
  transition: background 0.3s ease, color 0.3s ease, transform 0.2 ease;
  }
  
  .elearning-section__cta-button:hover {
    background-color: var(--secondary-color);
  }
  
/* Responsive adjustments */
@media (max-width: 768px) {
  .elearning-section__content-container {
      flex-direction: column;
      text-align: center;
      padding: 20px;
  }

  .elearning-section__image-content {
      margin-bottom: 20px;
      padding-right: 0;
      text-align: center;
  }

  .elearning-section__illustration {
      max-width: 100%;
  }

  .elearning-section__title {
      font-size: 1.5rem; /* Adjusted for medium screens */
  }

  .elearning-section__feature-list li {
      font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .elearning-section__content-container {
      padding: 10px;
  }

  .elearning-section__title {
      font-size: 1.2rem;
  }

  .elearning-section__feature-list li {
      font-size: 0.9rem;
  }

  .elearning-section__cta-button {
      font-size: 0.9rem;
      padding: 8px 16px;
  }
}