@import url('https://fonts.googleapis.com/css2?family=Oxanium:wght@200..800&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
/* Full-screen video container styling */
/* Full-screen video container styling */
.video-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

/* Video styling to cover the background */
.video-container video {
  width: 100%;
  height: 90vh;
  object-fit: cover;
}


.catchingPhrase {
  position: absolute;
  top: 50%; /* Adjust to control vertical positioning */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the text */
  color: white; /* Text color for contrast */
  font-size: 70px; /* Adjust font size as needed */
  text-align: center;
  padding: 10px;
  font-family: "Titillium Web", sans-serif;
  font-weight: 900;
  letter-spacing: 2px;
  width : 70%
}

/* Keyframes for plane animation */
@keyframes flyAcross {
  0% {
      transform: translateX(-100px) scale(1);
      opacity: 0;
  }
  10% {
      opacity: 1;
  }
  50% {
      transform: translateX(100vw) scale(1.2); /* Add scale for zoom effect */
      opacity: 1;
  }
  90% {
      opacity: 1;
  }
  100% {
      transform: translateX(110vw) scale(1.4); /* Finish slightly off-screen */
      opacity: 0;
  }
}

@media (max-width: 768px) {
  .video-container video {
   
    margin-top: 0px ;
  }
}
