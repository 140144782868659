.company-launch-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  background-color: #ffffff;
}

.company-launch-container .text-section {
  flex: 1;
  padding-right: 20px;
}

.company-launch-container .text-section h1 {
  font-size: var(--subtitle);
  font-family: var(--title-fontstyle);
  font-weight: bold;
  margin: 0 0 20px;
  color: var(--primary-color);
}

 .company-launch-container .text-section p {
  font-size: 1.1em;
  color: #333333;
  margin-bottom: 30px;
  line-height: 1.6;
}
.company-launch-container .text-section p span {
  color : var(--tertiary-color);
  font-weight: bold ;
}
.company-launch-container .image-section {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.company-launch-container .image-section img {
  width : 500px ;
}

.company-launch-container .team-image {
  border-radius: 50%;
  object-fit: cover;
}

.text-section .button {
  display: inline-block;
  font-size: var(--text-size);
  font-family: var(--text-fontstyle);
  padding: 10px 20px;
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  background-color: var(--logo-yellow);
  transition: background 0.3s ease, color 0.3s ease, transform 0.2 ease;
}

.button:hover {
  background: var(--secondary-color);
  color: #fff;
  transform: translateY(-2px);
}

/* Tablet Styles */
@media (max-width: 1024px) {
  .company-launch-container {
      flex-direction: column;
      padding: 30px;
  }

  .text-section {
      padding-right: 0;
      text-align: center;
  }

  .text-section h1 {
      font-size: 2em;
  }

  .text-section p {
      font-size: 1em;
  }

  .image-section {
      justify-content: center;
      margin-top: 20px;
  }

  .team-image {
      width: 80%;
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .company-launch-container {
      padding: 20px;
  }

  .text-section h1 {
      font-size: 1.8em;
  }

  .text-section p {
      font-size: 0.95em;
      margin-bottom: 20px;
  }

  .launch-button {
      font-size: 0.9em;
      padding: 10px 16px;
  }

  .button {
      font-size: 0.9em;
      padding: 8px 16px;
  }
  .company-launch-container .image-section {
    display : flex ;
    align-items: center;
    justify-content: center;
    width : 100% ;
  }
  .company-launch-container .image-section img {
      display: block ;
      width : 250px ;
  }
}

/* Small Mobile Styles */
@media (max-width: 480px) {
  .text-section h1 {
      font-size: 1.5em;
  }

  .text-section p {
      font-size: 0.9em;
  }

  .team-image {
      width: 60%;
  }

  .launch-button, .button {
      padding: 8px 14px;
      font-size: 0.85em;
  }
}