.carousel-container {
    padding: 20px; /* Réduit le padding pour les petits écrans */
    background-color: #ffffff;
    overflow: hidden;
  }
  
  .carousel-container .carousel-content {
    margin: 0 auto;
    text-align: center;
  }
  
  .carousel-container .carousel-content .carousel-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--primary-color);
    margin-bottom: 1rem;
    line-height: 1.2;
    text-align: center;
  }
  
  .highlight-client {
    color: var(--primary-color);
  }
  
  .carousel-subtitle {
    font-size: 1.25rem;
    color: #666;
    margin-bottom: 3rem;
  }
  .carousel-subtitle span {
    color : var(--tertiary-color);
    font-weight: 700;
  }
  
  .logo-track-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    padding: 2rem 0;
  }
  
  .logo-track {
    display: flex;
    animation: scroll 30s linear infinite;
    width: fit-content;
  }
  
  .logo-item {
    flex: 0 0 200px;
    height: 100px;
    margin: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
  }
  
  .logo-item img {
    max-width: 100%;
    max-height: 100%;
    width : 270px ;
    object-fit: contain;
    opacity: 0.7;
    transition: all 0.3s ease;
  }
  
  .logo-item:hover img {
    opacity: 1;
    transform: scale(1.05);
  }
  
  /* Animation de défilement */
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  
  /* Styles pour les tablettes et les mobiles */
  @media (max-width: 1024px) {
    .carousel-container .carousel-content .carousel-title {
      font-size: 2rem;
    }
  
    .carousel-subtitle {
      font-size: 1.1rem;
      margin-bottom: 2.5rem;
    }
  
    .logo-item {
      flex: 0 0 150px;
      height: 80px;
      margin: 0 1.5rem;
    }
  }
  
  @media (max-width: 768px) {
    .carousel-container .carousel-content .carousel-title {
      font-size: 1.75rem;
    }
  
    .carousel-subtitle {
      font-size: 1rem;
      margin-bottom: 2rem;
    }
  
    .logo-item {
      flex: 0 0 120px;
      height: 70px;
      margin: 0 1rem;
    }
  
    .carousel-container {
      padding: 15px;
    }
  }
  
  @media (max-width: 480px) {
    .carousel-container .carousel-content .carousel-title {
      font-size: 1.50rem;
    }
  
    .carousel-subtitle {
      font-size: 0.9rem;
      margin-bottom: 1.5rem;
    }
  
    .logo-item {
      flex: 0 0 100px;
      height: 60px;
      margin: 0 0.5rem;
    }
  }
  