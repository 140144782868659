.container {
    display: flex;
    flex-direction: row; /* Par défaut, les éléments sont côte à côte */
    margin: 0 auto;
    color: #212529;
    background: rgb(255, 255, 255);
    align-items: center;
    justify-content: center;
    padding: 40px;
}

/* Section de texte */
.container .text-section {
    flex: 1;
    padding: 20px;
}

.text-section .title {
    font-family: "Titillium Web", sans-serif;
    font-size: 3rem;
    font-weight: bold;
    letter-spacing: 1.5px;
    margin: 0;
    color : var(--primary-color)
}

.text-section .subtitle {
    font-family: var(--text-fontstyle);
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 10px 0 20px;
    color: var(--primary-color);
}

.container .text-section p {
    font-family: var(--text-fontstyle);
    font-size : var(--text-size);
    color: #212529;
    line-height: 1.5;
    margin-bottom: 20px;
    padding: 5px;
}
.container .text-section .subtitle span {
    color : var(--tertiary-color);
    font-weight: bold;
}

.container .button {
    display: inline-block;
    font-size: 1rem;
    font-family: var(--text-fontstyle);
    padding: 10px 20px;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    background-color: var(--primary-color);
    transition: background 0.3s ease, color 0.3s ease, transform 0.2s ease;
}

.container .button:hover {
    background: var(--secondary-color);
    color: #fff;
    transform: translateY(-2px);
}

/* Section d'image */
.container .image-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.container .image-section img {
    width: 95%;
    max-width: 500px;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
}

/* File: DigiformaSection.css */

.about-wrapper .digiforma-section{
    padding: 60px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    text-align: center;
  }
  
  .digiforma-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    max-width: 1200px;
    width: 100%;
  }
  
  .digiforma-image img {
    width: 100%;
    max-width: 700px;
    border-radius: 8px;
  }
  
  .digiforma-section .digiforma-content .digiforma-text {
    max-width: 700px;
    text-align: left;
  }
  
  .digiforma-section .digiforma-content .digiforma-text  h2  {
    font-size: 2em;
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  .digiforma-section .digiforma-content .digiforma-text span {
    color : var(--logo-yellow);
    font-weight: bold;
  }
  .digiforma-section .digiforma-content .digiforma-text {
    font-size: 1em;
    line-height: 1.6;
    color: #555;
    margin-bottom: 15px;
  }
  
  .cta-section {
    background: #e3f2fd;
    padding: 20px;
    border-radius: 20px;
    max-width: 800px;
    width: 100%;
    text-align: center;
  }
  
  .cta-section p {
    font-size: 1.2em;
    color: #2c1e55;
  }
  
  .cta-section a {
    color: var(--primary-color);
    font-weight: bold;
    text-decoration: none;
  }
  
  .cta-section a:hover {
    text-decoration: underline;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .digiforma-content {
      flex-direction: column;
      text-align: center;
    }
  
    .digiforma-text {
      text-align: center;
    }
  }
  
  @media (max-width: 768px) {
    .digiforma-text h2 {
      font-size: 1.8em;
    }
  
    .cta-section p {
      font-size: 1em;
    }
  }
  

/* Responsive pour les mobiles */
@media (max-width: 768px) {
    .container {
        padding: 20px;
    }

    .image-section {
        order: -1; /* Place l'image en premier sur mobile également */
    }

    .text-section .title {
        font-size: 2rem;
    }

    .text-section .subtitle {
        font-size: 1.2rem;
        margin: 8px 0 16px;
    }

    .container .text-section p {
        font-size: 0.9rem;
        line-height: 1.4;
    }

    .container .button {
        padding: 6px 12px;
        font-size: 0.85rem;
        text-align: center;
    }

    .container .image-section img {
        width: 100%; /* Prend toute la largeur sur mobile */
    }
}
@media (max-width: 1024px) {
    .container {
        flex-direction: column; /* Passe les éléments en colonne */
        padding: 20px;
        text-align: center;
    }

    .image-section {
        order: -1; /* Place l'image en premier */
    }

    .text-section .title {
        font-size: 2.5rem;
    }

    .text-section .subtitle {
        font-size: 1.4rem;
    }

    .container .text-section p {
        font-size: 1rem;
    }

    .container .button {
        padding: 8px 16px;
        font-size: 0.95rem;
    }

    .container .image-section img {
        width: 80%; /* Réduit la taille de l'image sur tablette */
    }
}
/* Extra Small Mobile Screens */
@media (max-width: 480px) {
    .text-section .title {
        font-size: 1.8rem;
    }

    .text-section .subtitle {
        font-size: 1.1rem;
    }

    .container .text-section p {
        font-size: 0.85rem;
    }

    .container .button {
        padding: 5px 10px;
        font-size: 0.8rem;
    }
}
