/* Navbar CSS */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  position: relative;
}

.navbar .navbar-brand {
  flex: 1;
}

.navbar .navbar-brand img {
  width: 90px;
}

/* Centered Menu */
.navbar .navbar-links {
  display: flex;
  gap: 2rem;
  align-items: center;
  flex: 2;
}

.navbar .navbar-links .nav-item {
 
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #4a4a4a;
    font-size: var(--text-size);
    font-family: var(--text-fontstyle);
    font-weight: bold;
    border: solid 2px var(--primary-color);
    border-radius: 27px;
    padding: 12px;
    transition: transform 0.4s ease, box-shadow 0.4s ease, background-color 0.3s ease;
  
  
}
.navbar .navbar-links a {
  text-decoration: none; /* Retire le soulignement */
  color: inherit; /* Garde la couleur de texte héritée */
  font-size: 0.8em;
}

.navbar .navbar-links .nav-item:hover {
  color: white;
  background: var(--primary-color);
  box-shadow: 0px 12px 15px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow effect */
  transform: translateY(-5px) scale(1.25); /* Lifts and scales the item slightly */
}

.navbar-links .nav-item span {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.nav-item:hover {
  color: var(--primary-color);
}

/* Button Connexion aligné à droite */
.navbar-actions {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.btn-outline {
  padding: 0.4rem 0.8rem;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  background: transparent;
  border-radius: 6px;
  font-size: 0.85rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.btn-outline:hover {
  background: var(--primary-color);
  color: white;
}

/* Menu Hamburger caché par défaut sur les grands écrans */
.hamburger-menu {
  display: none;
}

.navbar .toggle-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.navbar .toggle-container .toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--logo-yellow); /* Color when toggled on */
}

input:checked + .slider:before {
  transform: translateX(26px); /* Moves the toggle circle to the right */
}


/* Customize the dropdown style */

/* Responsive Styles */
 @media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: center;
  }

  .toggle-container {
    margin-top: 10px;
    justify-content: center;
  }
} 
@media (max-width: 1024px) {
  .navbar {
    padding: 1rem 1.5rem;
  }

  /* Display hamburger menu on mobile */
  .hamburger-menu {
    display: block;
    font-size: 1.8rem;
    cursor: pointer;
    color: #333;
    position: absolute;
    right: 1rem;
  }

  /* Cache les liens de navigation par défaut sur mobile */
  .navbar .navbar-links,
  .navbar .navbar-actions {
    display: none;
  }

  /* Affiche le menu en mode colonne lorsque l'icône est activée */
  .navbar-links.mobile.active,
  .navbar-actions.mobile.active {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 70px;
    left: 0;
    width: 91%;
    background-color: white;
    padding: 1rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    gap: 1.5rem;
    z-index: 100;
  }

  /* Styles pour les éléments de navigation en mode mobile */
  .navbar-links .nav-item {
    font-size: 1rem;
  }

  .navbar .navbar-brand img {
    width: 80px;
  }

  /* Bouton Connexion en mode mobile */
  .navbar-actions .btn-outline {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .navbar-links .nav-item {
    font-size: 0.9rem;
    padding: 10px;
  }

  .btn-outline {
    padding: 0.3rem 0.6rem;
    font-size: 0.75rem;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 0.5rem ;
  }

  .navbar .navbar-brand img {
    width: 70px;
  }

  .nav-item span {
    font-size: 0.85rem;
  }

  .btn-outline {
    padding: 0.25rem 0.5rem;
    font-size: 0.7rem;
  }
}
