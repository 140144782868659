/* Floating toggle button */
.chatbot-toggle-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: linear-gradient(135deg, #6dd5ed, #2193b0);
  border: none;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  cursor: pointer;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.chatbot-toggle-button:hover {
  transform: scale(1.1);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.3);
}

.chatbot-toggle-button img {
  width: 40px;
  height: 40px;
}

/* Chatbot container as a floating box */
.chatbot-container {
  position: fixed;
  bottom: 100px; /* Adjust to be above the toggle button */
  right: 20px;
  width: 400px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(135deg, #f3e7e9, #e3eeff);
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  animation: slideUp 0.3s ease-out;
  z-index: 10;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

/* Chatbot box styling */
.chatbot-box {
  width: 100%;
  padding : 15px ;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
}

/* Header styling */
.chatbot-header {
  background: var(--primary-color) ;
  color: white;
  text-align: center;
  padding: 15px;
  font-size: 22px;
  border-radius: 10px ;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Messages container */
.chatbot-messages-container {
  flex: 1;
  padding: 15px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #f8f9fa;
}

/* Message styling */
.chatbot-message {
  padding: 15px;
  border-radius: 10px;
  max-width: 75%;
  word-wrap: break-word;
  font-size: 16px;
}

.bot-message {
  align-self: flex-start;
  background: var(--secondary-color);
  color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.user-message {
  align-self: flex-end;
  background : #16db65;
  color: #333;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Input container styling */
.chatbot-input-container {
  display: flex;
  align-items: center;
  border-top: 1px solid #ddd;
  padding: 10px;
  background-color: #f3f4f6;
}

.chatbot-input {
  flex: 1;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #ddd;
  outline: none;
  font-size: 16px;
}

.chatbot-send-button {
  margin-left: 10px;
  padding: 10px 20px;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s ease;
}

.chatbot-send-button:hover {
  background: var(--tertiary-color);
}