.page-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    /* min-height: 80vh; */
    flex-direction: row;
}

.page-container .text-section {
    max-width: 50%;
}

.page-container .text-section .title {
    font-size: 3.5rem;
    font-weight: bold;
    color:var(--primary-color);
    margin-bottom: 10px;
}

.page-container .text-section p {
    font-size: 1.4rem;
    color: #212529;
    line-height: 1.6;
}

.page-container .image-section img {
    position: relative;
    /* max-width: 40%; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-section .circular-image {
    width: 100%;
    height: 60vh;
    overflow: hidden;
    border-radius: 50%; /* Circle effect */
}

.image-section .circular-image img {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
}

.formaWrapper .title-section {
    color: var(--primary-color);
    letter-spacing: 1px;
    font-family: var(--text-fontstyle);
    font-size: var(--subtitle);
    text-align: center;
    font-weight: bold;
    padding: 10px;
    margin-bottom: 30px ;
}

.formaWrapper .trainings-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    max-width: 80%;
    margin: auto;
    padding: 20px;
    justify-items: center;
}

.formaWrapper .trainings-container .training-card {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    width: 100%;
    max-width: 300px;
    padding: 24px;
    overflow: hidden;
    text-align: left;
    transition: transform 0.3s;
}

.training-card:hover {
    transform: scale(1.05);
}

.training-image {
    width: 100%;
    height: 200px;
    border-radius: 10px;
    /* object-fit: cover; */
}

.training-badge {
    font-size: 0.9rem;
    color: #666;
    text-transform: uppercase;
    margin-top: 10px;
    font-weight: bold;
}

.trainings-container .training-card .training-content h2 {
    font-size: 1.3rem;
    font-weight: bold;
    color: inherit;
    margin: 10px 0;
    line-height: 1.2;
}

.training-description {
    font-size: 1rem;
    color: #666;
    line-height: 1.4;
}
.training-link {
    color: inherit; /* Match the text color */
    text-decoration: none; /* Remove underline */
  }
  
  .training-link:hover {
    text-decoration: underline; /* Underline on hover */
  }
  
/* Responsive Styles for Tablets */
@media (max-width: 1024px) {
    .formaWrapper .page-container {
        flex-direction: column;
        align-items: center;
        padding: 20px;
        text-align: center;
    }

    .formaWrapper .page-container .text-section {
        max-width: 100%;
        margin-bottom: 20px;
    }

    .page-container .text-section .title {
        font-size: 1.8em;
    }

    .formaWrapper .trainings-container {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }

    .formaWrapper .trainings-container .training-card {
        max-width: 100%;
    }

    
}

/* Responsive Styles for Mobile */
@media (max-width: 768px) {
    .formaWrapper .page-container {
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }

    .formaWrapper .page-container .text-section {
        max-width: 100%;
        padding: 0 10px;
    }

    .page-container .text-section .title {
        font-size: 1.7em;
        max-width: 100%;
    }

    .formaWrapper .trainings-container {
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .formaWrapper .trainings-container .training-card {
        width: 100%;
        padding: 20px;
    }

    .page-container .image-section .circular-image {
        height: 30vh;
    }

    .page-container .text-section p {
        font-size: 1.2rem;
    }
}

@media (max-width: 480px) {
    .page-container .text-section 
    .title {
        font-size: 2em;
    }

    .page-container .text-section p {
        font-size: 1rem;
        padding: 0 10px;
    }

    .training-description {
        font-size: 0.9rem;
    }
}
