.card-container {
  display: flex;
  gap: 100px;
  padding: 30px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: #f8f9fc;
}

/* Card styling */
.card {
  width: 300px;
  min-height: 400px;
  border-radius: 20px;
  background: linear-gradient(145deg, #ffffff, #e6e6e6);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1), 0 5px 10px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 10px rgba(0, 0, 0, 0.1);
}

/* Image wrapper for consistent sizing */
.card-image-wrapper {
  height: 200px;
  overflow: hidden;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.card:hover .card-image {
  transform: scale(1.05);
}

/* Content styling */
.card-content {
  padding: 20px;
  text-align: center;
}

.card-title {
  font-size: 1.6rem;
  font-weight: bold;
  color: #333;
  margin: 15px 0 10px;
  letter-spacing: 1px;
}

.card-description {
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
}

/* Responsive design */
@media (max-width: 768px) {
  .card-container {
    flex-direction: column;
    align-items: center;
  }
}