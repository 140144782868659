body {
  margin: 0;
  padding : 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-color: #20c5be;
  --secondary-color: #3f8efc;
  --tertiary-color : #2dc653 ;
  --logo-yellow : #fdb522 ;
  --subtitle : 2.5em ;
  --text-size : 1.1em;
  --text-fontstyle : 'Titillium web' sans-serif ;
}