/* General container styling */
.contact-container {
  display: flex;
  width : 100%;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background: rgb(255, 255, 255);
}

.contact-header {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 20px 0;
  width: 100%;
}

.contact-header h1 {
  font-family: 'Titillium Web', sans-serif;
  font-size: 4em;
  font-weight: bold;
  color: var(--primary-color);
}

.contact-container .contact-content {
  display: flex;
  justify-content: space-between;
  padding: 40px;
  gap: 20px;
  width : 80% ;
}

.contact-left-section,
.contact-right-section {
  flex: 1;
}

.contact-left-section {
  padding-right: 80px;
}

.contact-left-section h2 {
  font-family: 'Titillium Web', sans-serif;
  font-size: 2.5em;
  font-weight: bold;
  letter-spacing: 1px;
  color: var(--primary-color);
}

.contact-left-section p {
  font-family: 'Titillium Web', sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1px;
}

.contact-container .contact-right-section {
  /* padding-left: 80px; */
  width : 100% ;
}

.contact-right-section h2 {
  font-family: var(--text-fontstyle);
  font-size: var(--subtitle);
  letter-spacing: 1px;
  font-weight: bold;
  color: var(--primary-color);
}

.contact-info {
  margin-top: 20px;
}

.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px;
  gap: 15px;
}

.contact-item img {
  width: 30px;
  background: rgba(222, 226, 230, 0.5);
  border-radius: 50%;
  padding: 5px;
}

.contact-info a {
  padding: 5px;
  color: #212529;
}

.contact-item p {
  padding: 5px;
  font-family: 'Titillium Web', sans-serif;
  font-size: 17px;
}

.contact-item span {
  margin-right: 10px;
}

.snd-btn {
  display: inline-block;
  font-size: var(--text-size);
  font-family: var(--text-fontstyle);
  padding: 10px 20px;
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  background-color: var(--logo-yellow);
  transition: background 0.3s ease, color 0.3s ease, transform 0.2s ease;
}

.snd-btn:hover {
  background: var(--primary-color);
  color: #fff;
  transform: translateY(-2px);
  cursor: pointer;
}

.input-group {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  padding: 5px;
}

/* Responsive Styles for Tablets (1024px and below) */
@media (max-width: 1024px) {
  .contact-container .contact-content {
    padding: 20px;
    gap: 20px;
  }

  .contact-left-section {
    padding-right: 40px;
  }

  .contact-right-section {
    padding-left: 40px;
  }

  .contact-header h1 {
    font-size: 3em;
  }

  .contact-left-section h2 {
    font-size: 2em;
  }

  .contact-left-section p {
    font-size: 18px;
  }
}

/* Responsive Styles for Small Tablets and Large Mobile (768px and below) */
@media (max-width: 768px) {
  .contact-content {
    flex-direction: column;
    align-items: center;
    /* text-align: center; */
    padding: 20px;
  }

  .contact-left-section,
  .contact-right-section {
    padding: 0;
    margin-bottom: 20px;
    /* text-align: center; */
  }

  .contact-header h1 {
    font-size: 2.5em;
  }

  .contact-left-section h2,
  .contact-right-section h2 {
    font-size: 1.8em;
  }

  .contact-left-section p {
    font-size: 1rem;
    line-height: 1.4;
  }

  .input-group {
    flex-direction: column;
    gap: 10px;
  }

  .contact-item img {
    width: 40px; /* Larger for better visibility */
  }

  .snd-btn {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    font-size: 1rem;
    padding: 12px;
  }
}

/* Responsive Styles for Mobile (480px and below) */
@media (max-width: 480px) {
  .contact-header h1 {
    font-size: 2em;
  }

  .contact-left-section h2,
  .contact-right-section h2 {
    font-size: 1.5em;
  }

  .contact-left-section p {
    font-size: 16px;
  }

  .contact-item img {
    width: 30px;
  }

  .snd-btn {
    font-size: 1rem;
    padding: 10px 15px;
    width: 100%;
    text-align: center;
  }
}
