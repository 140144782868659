.digiforma-section {
    background-color: #f8f9fa ;
    border-radius : 15px ;
    margin-bottom: 1em;
  }
  
 .content-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
  }
  
  .content-container .text-content {
    flex: 1;
    padding-right: 20px;
  }
  
  .text-content .title {
    font-size: var(--subtitle);
    font-weight: bold;
    color: var(--primary-color);  
    margin-bottom: 1.5rem;
  }
  
  .text-content .feature-list {
    list-style: disc;
    padding-left: 0;
  }
  
  .feature-list li {
    margin-bottom: 1rem;
    font-size: 1.1rem;
    line-height: 1.6;
    color: #444; /* Darker text for readability */
  }
  
  .text-content .cta-button {
    margin-top: 1.2em;
    display: inline-block;
    font-size: var(--text-size);
    font-family: var(--text-fontstyle);
    padding: 10px 20px;
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    background-color: var(--primary-color);
    transition: background 0.3s ease, color 0.3s ease, transform 0.2
  }
  
  .text-content .cta-button:hover {
    background-color: var(--secondary-color); 
  }
  
  .content-container .image-content {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
  
  .content-container .image-content img {
    width: 100%;
    max-width: 500px;
    height: auto;
    display: block;
  }


  /* Responsive adjustments */
@media (max-width: 768px) {
  .content-container {
      flex-direction: column;
      padding: 20px;
  }

  .content-container .text-content {
      padding-right: 0;
      margin-bottom: 20px;
      text-align: center;
  }


  .text-content .title {
      font-size: 1.5rem; /* Adjusted font-size for smaller screens */
  }

  .feature-list li {
      font-size: 1rem;
      list-style: none;
  }

  .content-container .image-content {
      justify-content: center;
  }

  .content-container .image-content img {
      max-width: 100%;
  }
}

@media (max-width: 480px) {
  .content-container {
      padding: 10px;
  }

  .text-content .title {
      font-size: 1.2rem;
  }

  .feature-list li {
      font-size: 0.9rem;
  }

  .text-content .cta-button {
      font-size: 0.9rem;
      padding: 8px 16px;
  }
}
  