/* Styles for the hero layout */
.hero-layout {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

/* Navbar styling to ensure it appears above the video */
.hero-layout .navbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10; /* Ensures the Navbar is above the video */
}

/* Ensure the HeroSection video covers the full background */
.hero-layout .video-container {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; /* Place video below Navbar */
}
