@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

.footer {
    background-color: #1a1a1a;
    color: #e1e1e1;
    padding: 40px 20px;
    display: grid;
    margin: auto;
    grid-template-columns: repeat(2, 1fr);
    gap: 60px;
    font-family: "Titillium Web", sans-serif;
}

.branding {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center;
    text-align: center;
}
.branding h1 {
    font-weight: 600;
    color : var(--primary-color);
}
.branding h1 span {
    font-family: "Great Vibes", cursive;
    font-weight: 400;
    font-style: normal;
    color : var(--logo-yellow);
}

.branding .footer-logo {
    width: 25%;
    height: auto;
    margin-bottom: 10px;
}

.social-icons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    justify-content: center;
}

.social-icons i {
    font-size: 1.5rem;
    color: #e1e1e1;
    cursor: pointer;
    padding: 10px;
    background-color: #333;
    border-radius: 5px;
    transition: color 0.3s;
    width: 40px;
}

.social-icons i:hover {
    color: #3772ff;
}

.footer-description {
    font-size: 1rem;
    margin: 10px 0;
    text-align: center;
}

.links
 {
    text-align: center;
}

.links h4
{
    font-size: 1.2rem;
    font-weight: bold;
    color: #fff;
    margin-bottom: 15px;
}

.links ul {
    list-style: none;
    padding: 0;
    line-height: 2;
}

.links li { 
    cursor: pointer;
    margin-bottom: 10px;
    font-size: var(--textsize);
    padding: 5px 0;
    transition: ease-in-out 0.3s, transform 0.3s;
}

.links li:hover
{
    color: var(--logo-yellow);
    font-size: 1.3em;
    transform: translateX(5px);
}

.footer-bottom {
    grid-column: 1 / -1;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #444;
    padding-top: 10px;
    margin-top: 20px;
    font-size: 0.9rem;
    color: #aaa;
    text-align: center;
}

.footer-bottom img {
    width: 162px;
    height: 108px;
}

.qualiopi-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 160px;
    width: 20%;
    padding: 10px;
}
.download-btn {
    background-color: #20C5BE;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin-top: 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
    text-align: center;
  }
  
  .download-btn a {
    text-decoration: none;
    color: inherit;
  }
  
  .download-btn:hover {
    background-color: #06f8ec;
  }
  

/* Tablet Styles */
@media (max-width: 1024px) {
    .footer {
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
    }

    .footer-bottom {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer-bottom img {
        width: 130px;
    }

    .branding .footer-logo {
        width: 30%;
    }

    .footer-description {
        font-size: 0.9rem;
    }

    .social-icons i {
        font-size: 1.3rem;
        padding: 8px;
        width: 35px;
    }
}

/* Mobile Styles */
@media (max-width: 768px) {
    .footer {
        grid-template-columns: 1fr;
        gap: 30px;
        padding: 30px 15px;
    }

    .branding .footer-logo {
        width: 35%;
    }

    .footer-description,
    .links h4,
    .formations h4,
    .newsletter h4 {
        font-size: 1rem;
    }

    .footer-bottom {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding-top: 15px;
    }

    .footer-bottom img {
        width: 120px;
        height: auto;
    }

    .qualiopi-logo {
        width: 50%;
    }

    .social-icons i {
        font-size: 1.2rem;
        padding: 6px;
        width: 30px;
    }
}

/* Extra Small Devices */
@media (max-width: 480px) {
    .footer {
        padding: 10px 10px;
        gap: 20px;
    }

    .footer-description,
    .links h4,
    .formations h4,
    .newsletter h4 {
        font-size: 0.9rem;
    }

    .branding .footer-logo {
        width: 50%;
    }

    .social-icons i {
        font-size: 1rem;
        width: 25px;
        padding: 5px;
    }

    .footer-bottom img {
        width: 100px;
    }

    .qualiopi-logo {
        width: 60%;
    }

    .footer-bottom {
        font-size: 0.8rem;
    }
}
